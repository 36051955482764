import { useNavigate } from 'react-router-dom';
import { memo, useRef } from 'react';
import { Formik } from 'formik';
import { Grid, Typography, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { SignUpUserDto } from './SignUpUserDto';
import { useAuthControllerInitRecoverPasswordMutation } from '../../../app/services/futbolProdeApi';
import { Routes, useCompanyRoutes } from '../../../router';
import { useTranslation } from 'react-i18next';
import { Input } from './Input';

function InitRecoverForm() {
  const { t } = useTranslation();
  const { rootRoute } = useCompanyRoutes();
  const theme = useTheme();
  const formik: any = useRef(null);

  const navigate = useNavigate();
  const [initRecover, initRecoverResult] =
    useAuthControllerInitRecoverPasswordMutation();

  const user = new SignUpUserDto();

  const handleSubmit = async (values: SignUpUserDto) => {
    try {
      if (values.email) {
        await initRecover({
          email: values.email,
        }).unwrap();
        const queryString = `recover=${encodeURIComponent(values.email)}`;
        navigate(rootRoute(Routes.LOGIN + `?${queryString}`));
      }
    } catch (e: any) {
      if (formik.current) {
        formik.current!.setErrors({
          email:
            e.status === 404
              ? t('activation.emailNotFound')
              : t('activation.errorRecovering'),
        });
      }
    }
  };

  return (
    <Formik innerRef={formik} initialValues={user} onSubmit={handleSubmit}>
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <Typography variant="body1" color="inherit">
              {t('activation.enterEmailAddress', {
                name: user?.name ?? '',
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              autoComplete="off"
              value={values.email}
              name={'email'}
              label={t('login.email')}
              onChange={handleChange}
              error={errors.email}
            ></Input>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              loading={initRecoverResult.isLoading}
              fullWidth
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.main,
                fontWeight: 600,
                textTransform: 'capitalize',
                marginTop: 4,
              }}
            >
              {t('activation.sendRecoveryEmail')}
            </LoadingButton>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default memo(InitRecoverForm);
