import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { memo, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Alert, Typography, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ActivateUserDto } from './SignUpUserDto';
import createValidator from 'class-validator-formik';
import { Input } from './Input';
import {
  Invitation,
  futbolProdeApi,
  useCompaniesControllerFindMeQuery,
  CreateUserDto,
} from '../../../app/services/futbolProdeApi';
import { useAuthControllerAcceptInvitationByJwtMutation } from '../../../app/services/futbolProdeApi';
import { useAuthControllerAcceptInvitationMutation } from '../../../app/services/futbolProdeApi';
import { Routes, useCompanyAdminUrls, useCompanyRoutes } from '../../../router';
import { isNil } from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import { PasswordField } from './PasswordField';
import { When } from 'react-if';
import TermsAndConditionsSection from './TermsAndConditionsSection';

function ActivateAccountForm() {
  const { t } = useTranslation();
  const { adminRootRoute } = useCompanyAdminUrls();
  const { rootRoute } = useCompanyRoutes();
  const theme = useTheme();
  const { data: company } = useCompaniesControllerFindMeQuery();
  const formik: any = useRef(null);
  const [acceptsTermsAndConditions, setAcceptsTermsAndConditions] =
    useState(false);

  const [invitationParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  let [invitation, setInvitation] = useState(location.state?.invitation);
  const [errorFetchingInvitation, setErrorFetchingInvitation] = useState(false);
  const [trigger, result] =
    futbolProdeApi.useLazyInvitationsControllerFindOneByJwtQuery();

  const jwt = invitationParams.get('jwt');

  useEffect(() => {
    if (!isNil(jwt) && isNil(invitation)) {
      trigger(jwt)
        .unwrap()
        .then((response: Invitation) => {
          setInvitation(response);
        })
        .catch((_e) => {
          setErrorFetchingInvitation(true);
        });
    }
  }, [jwt, invitation, trigger]);

  const [createById, createByIdResult] =
    useAuthControllerAcceptInvitationMutation();
  const [createByJwt, createByJwtResult] =
    useAuthControllerAcceptInvitationByJwtMutation();

  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);

  const user: ActivateUserDto = {
    externalId: invitation?.externalId ?? '',
    name: invitation?.name ?? '',
    email: invitation?.email ?? '',
    password: '',
  };

  const validator = createValidator(ActivateUserDto);
  const handleValidation = (input: any) => ({
    ...(repeatedPassword === input.password
      ? {}
      : { password: t('validations.passwords.doNotMatch') }),
    ...validator(input),
  });

  const handleSubmit = async (values: ActivateUserDto) => {
    const createUserDto = values as CreateUserDto;
    try {
      if (!isNil(jwt)) {
        await createByJwt({ jwt, createUserDto }).unwrap();
        window.location.href = adminRootRoute(Routes.LOGIN);
      } else {
        await createById({
          invitationId: invitation?.id.toString()!,
          createUserDto,
        }).unwrap();
        navigate(rootRoute(`${Routes.LOGIN}?signup=true`));
      }
    } catch (e) {
      if (formik.current) {
        const errorCode = (e as any)?.data?.error;
        formik.current!.setErrors({
          email: t([
            `signup.errors.${errorCode}`,
            'activation.errorActivating',
          ]),
        });
      }
    }
  };

  const showPersonalDataSection =
    company?.allowUserEmailEdit || company?.allowUserNameEdit;

  if (errorFetchingInvitation) {
    return (
      <Typography variant="h4">
        {t('login.invitationNotFoundOrRedeemed')}
      </Typography>
    );
  }
  if (jwt && (result.isLoading || !user.externalId)) return null;
  return (
    <Formik
      innerRef={formik}
      initialValues={user}
      onSubmit={handleSubmit}
      validate={handleValidation}
      validateOnChange={false}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <Typography fontWeight="bold" color="inherit">
            {t('activation.welcome', { name: user?.name ?? '' })}
          </Typography>
          <Typography color="inherit">
            {t('activation.choosePassword')}
          </Typography>
          <PasswordField
            name="password"
            errors={''}
            placeholder={t('login.password')}
            value={values.password}
            onChange={handleChange}
            visibilityHook={[showPassword, setShowPassword]}
          />
          <PasswordField
            name="repeatPassword"
            errors={errors.password}
            placeholder={t('login.repeatPassword')}
            value={repeatedPassword}
            onChange={(e: any) => setRepeatedPassword(e.target.value)}
            visibilityHook={[showRepeatedPassword, setShowRepeatedPassword]}
          />
          <Alert severity="info" sx={{ mb: 1 }}>
            <Trans
              i18nKey="activation.flowDescription"
              values={{ externalId: user?.externalId }}
            >
              Ingresarás al sitio con tu usuario <b>id</b> y la contraseña que
              acabas de elegir.
            </Trans>
          </Alert>
          <When condition={showPersonalDataSection}>
            <Typography fontWeight="bold" color="inherit">
              {t('activation.updatePersonalData')}
            </Typography>
            {company?.allowUserEmailEdit && (
              <Input
                autoComplete="off"
                value={values.email}
                name={'email'}
                label={t('login.email')}
                onChange={handleChange}
                error={errors.email}
              />
            )}
            {company?.allowUserNameEdit && (
              <Input
                autoComplete="off"
                value={values.name}
                name={'name'}
                required
                label={t('signup.name')}
                onChange={handleChange}
                error={errors.name}
              />
            )}
          </When>
          <TermsAndConditionsSection
            value={acceptsTermsAndConditions}
            onChange={setAcceptsTermsAndConditions}
          />
          <LoadingButton
            loading={createByIdResult.isLoading || createByJwtResult.isLoading}
            fullWidth
            type="submit"
            variant="contained"
            disabled={!acceptsTermsAndConditions}
            sx={{
              backgroundColor: theme.palette.primary.main,
              fontWeight: 600,
              textTransform: 'capitalize',
            }}
          >
            {t('activation.submit')}
          </LoadingButton>
        </form>
      )}
    </Formik>
  );
}

export default memo(ActivateAccountForm);
