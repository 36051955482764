import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { Helmet } from 'react-helmet';
import { LinearProgress } from '@mui/material';
import { useMakeAppTheme } from '@futbolprode/ui-common';
import { useCompanyPageTitle } from './useCompanyPageTitle';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AppTourProvider from './features/tour/AppTourProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { useCompaniesControllerFindMeQuery } from './app/services/futbolProdeApi';
import { useEffect } from 'react';
import { isNil } from 'ramda';
import { setAppManifest } from './common/manifestHelpers';
import { MaintenanceModePage } from './ErrorPages';
import { Unless, When } from 'react-if';
import ReactGA from 'react-ga4';
import { isNilOrEmpty } from './common/ramdaExtensions';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
  }
}

export const availableLanguages = ['es', 'en', 'pt'] as const;

export default function App() {
  const { theme, googleFontsUrl, isLoading } = useMakeAppTheme();
  const { isLoading: companyIsLoading } = useCompanyPageTitle();
  const { data: company, isError } = useCompaniesControllerFindMeQuery();

  useEffect(() => {
    if (isNil(company)) {
      return;
    }

    setAppManifest(company);

    const trackingId = company.googleServicesConfig.analyticsTrackingId;
    if (!isNilOrEmpty(trackingId)) {
      ReactGA.initialize(trackingId!);
    }
  }, [company]);

  if ((isLoading || companyIsLoading) && !isError) return <LinearProgress />;
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="stylesheet" href={googleFontsUrl} />
        <style>{`body { background-color: ${theme.palette.action.hover}; }`}</style>
        <link rel="apple-touch-startup-image" href={company?.isologoUrl} />
      </Helmet>
      <Unless
        condition={company?.googleServicesConfig.allowSearchEngineIndexing}
      >
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </Unless>
      <When
        condition={!isNilOrEmpty(company?.googleServicesConfig.adSenseClientId)}
      >
        <Helmet>
          <script
            async
            src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${company?.googleServicesConfig.adSenseClientId}`}
            crossOrigin="anonymous"
          ></script>
        </Helmet>
      </When>
      {/* TODO: tomar el locale que corresponde, probablemente exportandolo desde ui-common */}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <CssBaseline />
        <AppTourProvider>
          {isError ? (
            <MaintenanceModePage />
          ) : (
            <RouterProvider router={router} />
          )}
        </AppTourProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
